import styled from 'styled-components';

export const ButtonContainer = styled.div`
    text-align: center;

    .btn,
    .select-company {
        min-width: 125px;
        min-height: 45px;
    }

    .cancel-button,
    .select-company {
        margin: 10px 10px 0px 0px;
    }
`;

export const CircularProgressContainer = styled.div`
    margin-right: 15px;
    margin-top: 2px;
`;

export const AutoCompleteContainer = styled.div`
    width: 100%;
    margin: 18px 0px 8px 0px;
`;
