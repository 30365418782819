export const ACTION_TYPES = {
    LOGIN_USER: 'LOGIN_USER',
    LOGOUT_USER: 'LOGOUT_USER',
};

export const loginUser = (data) => {
    // SET YOUR LOGIN INFO HERE
    // localStorage.setItem('isLoggedIn', (!data.companyId || data.companyId === '') && localStorage.getItem('groupCompanyId') && localStorage.getItem('groupCompanyId') === 'null' ? false : true);
    localStorage.setItem('isLoggedIn', true);
    localStorage.setItem('authToken', data.accessToken);

    let info = {
        email: data.email,
        phone: data.phoneNumber,
        name: data.company,
        companyId: data.companyId,
        id: data.userId,
        imageUrl: data.imageUrl,
        isIntegratedWithMovegistics: data.isIntegratedWithMovegistics,
        isIntegratedWithMoverInventory: data.isIntegratedWithMoverInventory,
    };

    let selectedCompanyGroupDetails;
    if (localStorage.getItem('groupCompanyId') && localStorage.getItem('groupCompanyId') !== 'null') {
        selectedCompanyGroupDetails = JSON.parse(localStorage.getItem('selectedCompanyGroupDetails'));
    }

    let nameToShow = 'Admin';
    if (data.company !== '') {
        if (localStorage.getItem('groupCompanyId') && localStorage.getItem('groupCompanyId') !== 'null') {
            nameToShow = data.name + (selectedCompanyGroupDetails?.companyName && selectedCompanyGroupDetails?.companyName !== '' ? ' - ' + selectedCompanyGroupDetails?.companyName : '');
            info.name = selectedCompanyGroupDetails?.companyName;
            info.companyId = localStorage.getItem('groupCompanyId');
        } else {
            nameToShow = data.name + (data.company ? ' - ' + data.company : '');
        }
    }

    localStorage.setItem('info', JSON.stringify(info));
    localStorage.setItem('name', nameToShow);

    let user = 0;

    if (data.role.roleName === 'Super Admin' || data.role.roleName === 'Admin') {
        user = 1;
    } else if (data.role.roleName === 'Company Manager' || data.role.roleName === 'Main Admin') {
        user = 3;
    } else {
        // user = 4;
        user = 2;
    }

    localStorage.setItem('user', user);

    return {
        type: ACTION_TYPES.LOGIN_USER,
        ...data,
    };
};

export const logOutUser = () => {
    localStorage.clear();

    return {
        type: ACTION_TYPES.LOGOUT_USER,
    };
};
