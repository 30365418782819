import styled, { createGlobalStyle } from 'styled-components';
import { FONTS, responsive } from './Constants';
import { COLORS } from 'Styles/Constants';

export const UtilityStyles = createGlobalStyle`
    * {
        box-sizing  : border-box;
        outline     : none;
    }

    html,
    body{
        margin: 0;
        padding: 0;
    }

    body{
        background-color    : #F4F7F8;
        font-family         : ${FONTS.PRIMARY};
        line-height         : 1.2;
        min-height          : 100vh;
        position            : relative;
    }

    #root {
        display: flex;
    }

    .full-height{
        height: 100%;
    }

    .full-width {
        width: 100%;
    }

    .flex {
        display: flex;
    }

    .f-v-center{
        align-items: center;
    }

    .f-h-center{
        justify-content: center;
    }

    .text-center{
        text-align: center;
    }

    .visible-md{
        display: none;
        ${responsive.TABLET`display:block`}
    }

    .hidden-md{
        ${responsive.TABLET`display:none`}
    }

    .plain-notification{
        background-color: #fff;
    }

    .elipsis {
        overflow        : hidden;
        text-overflow   : ellipsis;
        white-space     : nowrap;
    }
    
    .no-data-text {
        color       : #707070;
        font-size   : 15px;
        text-align  : center;
        width       : 100%;
    }

    .chip-menu-item {
        font-weight : 400;
    }

    .chip-menu-selected-item {
        font-weight : bold;
    }

    /* .MuiTableContainer-root {
        height: calc(100vh - 220px);
    } */

    .MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft,
    .MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignLeft.MuiTableCell-stickyHeader {
        padding-left: 0 !important;
    }
`;

export const BoxWrapper = styled.div`
    height: calc(100vh - 30px);
    width: 100%;
    flex-direction: column;

    .logo-wrapper {
        background-color: ${COLORS.PRIMARY};
        height: 100px;
        width: 500px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            height: 60px;
            width: 350px;
        }
    }
`;

export const BoxContainer = styled.div`
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 20px rgba(92, 111, 139, 0.12);
    padding: 30px;
    width: 500px;

    .form-title {
        text-align: center;
        font-weight: bold;
        color: rgb(52, 52, 52);
    }

    .form-container {
        margin: 25px auto 25px;
        width: 90%;

        .form-item {
            margin: 12px 0;
        }
    }

    .button-container {
        /* margin-bottom   : 25px; */
        text-align: center;

        .btn {
            min-width: 125px;
            min-height: 45px;
        }
    }

    .forgot-pwd {
        margin-top: 25px;
        text-align: center;
        color: rgb(255, 126, 25);
        cursor: pointer;
    }
`;
