import styled from "styled-components";
import Toolbar from '@material-ui/core/Toolbar';
import { DIMENTIONS } from "Styles/Constants";

export const ToolbarWrapper = styled(Toolbar)`
    min-height      : ${DIMENTIONS.TOPBAR}px;
    justify-content : space-between;
    .logo-wrapper {
        padding-left : 12px;
        img {
            width : 200px;
        }
    }
`
export const RightPanel = styled.div`
    .icon {
        color : #fff;
    }
    .user-name {
        display : inline-block;
        padding : 10px;
    }
`