// CORE
import React from 'react';
import { connect } from 'react-redux';

import Toolbar from '@material-ui/core/Toolbar';

// USER DEFINED
import Routes from "Routes/Route";
import TopBar from "./Commons/Topbar/Topbar";
import Sidebar from "./Commons/Sidebar/Sidebar";
import EventManager from "./EventManager/EventManager";
import { ContentWrapper } from "./Website.style";


function Website(props){

	return(
		<React.Fragment>
			{
				props.isLoggedIn &&
				<>
					<TopBar />
					<Sidebar />
				</>
			}
			<ContentWrapper>
				{props.isLoggedIn && <Toolbar /> }
				<Routes />
			</ContentWrapper>

			<EventManager />
		</React.Fragment>
	)
}


const mapReduxStateToProps = (state) => ({
	isLoggedIn: state.Auth.isLoggedIn,
})

export default connect(mapReduxStateToProps)(Website);
