import React from 'react';
import LazyLoader from '@loadable/component';

import {
    URL_DASHBOARD,
    URL_USER,
    URL_WAREHOURSE,
    URL_WAREHOURSE_LAYOUT,
    URL_WAREHOUSE_UNITS,
    URL_COMPANY,
    URL_UNITS,
    URL_CHANGE_PASSWORD,
    URL_CUSTOMER,
    URL_TAGS,
    URL_EDIT_PROFILE,
    URL_SHIPMENTS,
    URL_COMPANY_LABEL,
    URL_COMPANY_GROUP,
    // URL_STATIC_CONTENT,
} from 'Helpers/Paths';
import {
    Dashboard as DashboardIcon,
    Settings as SettingsIcon,
    Web as WebIcon,
    SupervisorAccount as UsersIcon,
    Business as BusinessIcon,
    PeopleAlt as PeopleAltIcon,
    EmojiTransportation as EmojiTransportationIcon,
    VpnKey as VpnKeyIcon,
    AccountCircle as AccountCircleIcon,
    // Description as DescriptionIcon,
    Dns as DnsIcon,
    LocalOffer as LocalOfferIcon,
    Label as LabelIcon,
    GroupWork as GroupWorkIcon
} from '@material-ui/icons';

export default [
    {
        path: '/',
        exact: true,
        component: LazyLoader(() => import('Components/Dashboard/Dashboard')),
    },
    {
        path: URL_DASHBOARD,
        exact: true,
        component: LazyLoader(() => import('Components/Dashboard/Dashboard')),
        sidebar: {
            name: 'Dashboard',
            icon: <DashboardIcon />,
        },
    },
    {
        path: 'company_management',
        sidebar: {
            name: 'Company Management',
            icon: <BusinessIcon />,
            showDivider: true,
            nested: [
                {
                    name: 'Company Group',
                    visible: false,
                    path: URL_COMPANY_GROUP,
                    icon: <GroupWorkIcon />,
                    component: LazyLoader(() => import('Components/CompanyGroup/CompanyGroup')),
                },
                {
                    name: 'Company',
                    visible: false,
                    path: URL_COMPANY,
                    icon: <BusinessIcon />,
                    component: LazyLoader(() => import('Components/Company/Company')),
                },
                {
                    name: 'Users',
                    path: URL_USER,
                    visible: true,
                    icon: <UsersIcon />,
                    component: LazyLoader(() => import('Components/User/User')),
                },
            ],
        },
    },
    {
        path: URL_CUSTOMER,
        visible: true,
        exact: true,
        component: LazyLoader(() => import('Components/Customer/Customer')),
        sidebar: {
            name: 'Customers',
            icon: <PeopleAltIcon />,
        },
    },
    {
        path: URL_SHIPMENTS,
        visible: true,
        exact: true,
        component: LazyLoader(() => import('Components/Shipment/Shipment')),
        sidebar: {
            name: 'Shipments',
            icon: <EmojiTransportationIcon />,
            // showDivider: true
        },
    },
    {
        path: 'warehouse_management',
        sidebar: {
            name: 'Warehouse Management',
            icon: <WebIcon />,
            showDivider: true,
            nested: [
                {
                    name: 'Unit Types',
                    path: URL_UNITS,
                    visible: true,
                    icon: <DnsIcon />,
                    component: LazyLoader(() => import('Components/UnitTypes/UnitTypes')),
                },
                {
                    name: 'Warehouses',
                    path: URL_WAREHOURSE,
                    visible: true,
                    icon: <WebIcon />,
                    component: LazyLoader(() => import('Components/Warehouse/WarehouseTable')),
                },
            ],
        },
    },
    // {
    //     path: URL_STATIC_CONTENT,
    //     exact: true,
    //     component: LazyLoader(() => import('Components/StaticContent/StaticContent')),
    //     sidebar: {
    //         name: "Static Content",
    //         icon: <DescriptionIcon />,
    //         showDivider: true,
    //         visible: false,
    //     }
    // },
    {
        path: URL_WAREHOURSE_LAYOUT,
        exact: true,
        component: LazyLoader(() => import('Components/Warehouse/Warehouse')),
    },
    {
        path: URL_WAREHOUSE_UNITS,
        exact: true,
        component: LazyLoader(() => import('Components/Units/Units')),
    },
    {
        sidebar: {
            name: 'Settings',
            icon: <SettingsIcon />,
            showDivider: true,
            nested: [
                {
                    name: 'Tags',
                    path: URL_TAGS,
                    visible: true,
                    icon: <LocalOfferIcon />,
                    component: LazyLoader(() => import('Components/Tags/Tags')),
                },
                {
                    name: 'Change Password',
                    path: URL_CHANGE_PASSWORD,
                    visible: true,
                    icon: <VpnKeyIcon />,
                    component: LazyLoader(() => import('Components/ChangePassword/ChangePassword')),
                },
                {
                    name: 'Label Template',
                    path: URL_COMPANY_LABEL,
                    visible: true,
                    icon: <LabelIcon />,
                    component: LazyLoader(() => import('Components/CompanyLabel/CompanyLabel')),
                },
            ],
        },
    },
    {
        name: 'Edit Profile',
        path: URL_EDIT_PROFILE,
        visible: true,
        icon: <AccountCircleIcon />,
        component: LazyLoader(() => import('Components/Profile/EditProfile')),
    },
];
