import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AppBar, IconButton, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { AccountCircle as AccountCircleIcon, PowerSettingsNew as LogoutIcon } from '@material-ui/icons';
import TransparentLogo from 'Assets/Images/transparent-logo.png'
import { Tooltip } from '@material-ui/core';
//API CALL
import { URL_EDIT_PROFILE, API_URL } from "Helpers/Paths";
import ApiHandler from "Helpers/ApiHandler";

// STYLES
import { ToolbarWrapper, RightPanel } from "./Topbar.style";

// REDUX 
import { logOutUser } from "Redux/Auth/Actions";
// import authAction from 'Redux/Auth/Actions';
// const { logout } = authAction;

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
}));

function Topbar(props) {
    const classes = useStyles();

    const handleAccounttClick = () => props.history.push(URL_EDIT_PROFILE);

    const handleLogout = async () => {
        const api = new ApiHandler();
        await api.get(API_URL.LOGOUT).then(response => {
            // props.logout();  
            props.logOutUser();
        })
    }

    return (
        <AppBar className={classes.appBar} position="fixed">
            <ToolbarWrapper>
                <div className="logo-wrapper">
                    <img src={TransparentLogo} alt="logo" />
                </div>
                <RightPanel>
                    <Typography className="user-name" variant="h6">Hi, {localStorage.getItem("name")}</Typography>
                    {localStorage.getItem("user") === "2" || localStorage.getItem("user") === "1" ?
                    <IconButton className="icon" onClick={handleAccounttClick}>
                        <Tooltip title="Edit Profile">
                            <AccountCircleIcon />
                        </Tooltip>
                    </IconButton>
                    : null}
                    <IconButton className="icon" onClick={handleLogout}>
                        <Tooltip title="Logout">
                            <LogoutIcon />
                        </Tooltip>
                    </IconButton>
                </RightPanel>
            </ToolbarWrapper>
        </AppBar>
    );
}

export default connect(null, {
    logOutUser
})(withRouter(Topbar));