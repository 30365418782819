import React from "react";
import { withRouter } from 'react-router-dom';

// DESIGN
import { Button, Typography, InputAdornment, CircularProgress, IconButton, OutlinedInput, InputLabel, FormControl } from "@material-ui/core";
// STYLES
import { BoxWrapper, BoxContainer } from "Styles/Utils";
import { CircularProgressContainer, ButtonContainer } from '../Pages.style';
// ICONS
import { VisibilityOff as VisibilityOffIcon, Visibility as VisibilityIcon } from '@material-ui/icons';
import TransparentLogo from 'Assets/Images/transparent-logo.png'

//FORMIK
import { Formik } from "formik";
import * as Yup from "yup";

// REDUX
import { showToast } from "Redux/General/Actions";
import { store } from "Redux/Store";

// CONSTANTS
import { URL_LOGIN, API_URL } from "Helpers/Paths";
import ApiHandler from "Helpers/ApiHandler";
import { useEffect } from "react";

function ResetPassword(props) {
    const [showPassword, setShowPassword] = React.useState(false);
    const [loader, setLoader] = React.useState(false);
    const [token, setToken] = React.useState('');

    useEffect(() => {
        if (props.location)
            setToken(props.location.search.split("=")[1])
    }, [props.location])

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const resetPassword = (values) => {
        const api = new ApiHandler();
        api.get(API_URL.TOKEN_VALIDITY + token)
            .then(response => {
                setLoader(true);
                api.post(API_URL.RESET_PASSWORD, {
                    data: {
                        password: values.newPassword,
                        token: token,
                    }
                }).then(response => {
                    setLoader(false);
                    store.dispatch(showToast(response.message, 'success'));
                    props.history.push(URL_LOGIN)
                }).catch(error => {
                    setLoader(false);
                    if (error.response)
                        store.dispatch(showToast(error.response.data.message, 'error'));
                    // console.log(error);
                });
            }).catch(error => {
                setLoader(false);
                if (error.response)
                    store.dispatch(showToast(error.response.data.message, 'error'));
                // console.log(error);
            });
    };

    const testSchema = Yup.object().shape({
        newPassword: Yup.string()
            .min(8, "Password must contain at least 8 characters")
            .max(16, "Password can not be more than 16 characters")
            .required(" "),
        confirmPassword: Yup.string()
            .required(" ")
            .oneOf([Yup.ref("newPassword")], "Password does not match")
    });

    const initialValues = {
        newPassword: "",
        confirmPassword: ""
    };

    return (
        <BoxWrapper className="flex f-v-center f-h-center">
            <div className="logo-wrapper">
                <img src={TransparentLogo} alt="logo" />
            </div>
            <BoxContainer className="box">
                <Typography variant="h4" className="form-title">
                    Reset Password
                </Typography>
                <Formik
                    validationSchema={testSchema}
                    initialValues={initialValues}
                    onSubmit={(values, actions) => {
                        // console.log(values);
                        actions.setSubmitting(false);
                        resetPassword(values);
                    }}
                >
                    {({ handleChange, values, errors, touched, handleSubmit, isSubmitting, setFieldTouched }) => {
                        return (
                            <>
                                <div className="form-container">
                                    <FormControl
                                        variant="outlined"
                                        className="form-item"
                                        error={touched.newPassword && Boolean(errors.newPassword)}
                                        required
                                        fullWidth
                                    >
                                        <InputLabel htmlFor="outlined-adornment-password">New password</InputLabel>
                                        <OutlinedInput
                                            id="newPassword"
                                            label="New Password"
                                            onChange={handleChange}
                                            type={showPassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            labelWidth={70}
                                        />
                                        {errors.newPassword}
                                    </FormControl>
                                    <FormControl
                                        variant="outlined"
                                        className="form-item"
                                        error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                                        required
                                        fullWidth
                                    >
                                        <InputLabel htmlFor="outlined-adornment-password">Confirm password</InputLabel>
                                        <OutlinedInput
                                            id="confirmPassword"
                                            label="Confirm Password"
                                            onChange={handleChange}
                                            type={showPassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            labelWidth={70}
                                        />
                                        {errors.confirmPassword}
                                    </FormControl>
                                </div>
                                <ButtonContainer>
                                    <Button className={"btn"} variant="contained" color="primary" onClick={handleSubmit}>
                                        {loader ?
                                            <CircularProgressContainer>
                                                <CircularProgress color="white" size={30} />
                                            </CircularProgressContainer>
                                            : null}
                                    Reset Password
                                </Button>
                                </ButtonContainer>
                            </>
                        );
                    }}
                </Formik>
            </BoxContainer>
        </BoxWrapper>
    )
}

export default withRouter(ResetPassword);