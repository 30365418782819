import styled from "styled-components";

export const ContentWrapper = styled.div`
    flex-grow   : 1;
    /* padding     : 15px; */
    padding     : 10px 0px 10px 10px;
    /* width       : 100%; */
    width       : calc(100vw - 270px);

    .paper-wrapper {
        width           : 100%;
        /* margin-bottom   : 16px; */
        margin-bottom   : 0;

        .table-wrapper {
            min-width: 750px;

            .visually-hidden {
                border  : 0;
                clip    : rect(0 0 0 0);
                height  : 1px;
                margin  : -1px;
                overflow: hidden;
                padding : 0px;
                position: absolute;
                top     : 20px;
                width   : 1px
            }
        }
    }
`