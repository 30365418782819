import styled from "styled-components";

import { Drawer } from '@material-ui/core';

import { COLORS, DIMENTIONS } from "Styles/Constants";

export const SidebarWrapper = styled(Drawer)`
    width : ${DIMENTIONS.DRAWER.FULL}px;

    .list-container {
        width : ${DIMENTIONS.DRAWER.FULL}px;

        .nested {
            padding-left : 30px;
        }

        .Mui-selected,
        .Mui-selected svg {
            color : ${COLORS.PRIMARY_DARK};
        }
    }
`