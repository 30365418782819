import React from 'react';
import { withRouter } from 'react-router-dom';

// DESIGN
import Dialog from 'Components/Commons/Dialog/Dialog';
import { TextField, DialogContentText } from '@material-ui/core';

//FORMIK
import { Formik } from "formik";
import * as Yup from "yup";

//REDUX
import { showToast } from "Redux/General/Actions";
import { store } from "Redux/Store";

// CONSTANTS
import { API_URL, URL_LOGIN } from "Helpers/Paths";
import ApiHandler from "Helpers/ApiHandler";

function ForgotPassword(props) {

    const { isOpen, handleClose } = props;

    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit()
        }
    };

    const handleSave = () => {
        handleSubmit();
    };

    const callAPI = (e) => {
        // props.handleClose();
        // props.history.push(URL_RESET_PASSWORD);
        const api = new ApiHandler();
        api.post(API_URL.FORGOT_PASSWORD, {
            data:
            {
                email: e.email,
            }
        }).then(response => {
            // alert(response.data.message);
            store.dispatch(showToast(response.message, 'success'));
            props.handleClose();
            props.history.push(URL_LOGIN);
        }).catch(error => {
            if (error.response)
                // alert(error.response.data.message);
                store.dispatch(showToast(error.response.data.message, 'error'));
            // console.log(error);
        })
    }

    const testSchema = Yup.object().shape({
        email: Yup.string()
            .email("Enter a valid email")
            .required(" "),
    });

    const initialValues = {
        email: "",
    };

    const formRef = React.useRef();

    return (
        <Dialog
            title="Forgot Password"
            maxWidth="xs"
            isOpen={isOpen}
            handleClose={handleClose}
            handleSave={handleSave}
        >
            <DialogContentText>
                To reset your password, please enter your email address here. We will send reset password link.
            </DialogContentText>
            <Formik
                validationSchema={testSchema}
                innerRef={formRef}
                initialValues={initialValues}
                onSubmit={(values, actions) => {
                    actions.setSubmitting(false);
                    // console.log(values);
                    callAPI(values);
                }}
            >
                {({ handleChange, values, errors, touched, handleSubmit, isSubmitting, setFieldTouched }) => {
                    return (
                        <>
                            <div className="form-container">
                                <TextField
                                    className="form-item"
                                    label="Email Id"
                                    name="email"
                                    margin="normal"
                                    variant="outlined"
                                    error={touched.email && Boolean(errors.email)}
                                    onChange={handleChange("email")}
                                    value={values.email}
                                    fullWidth
                                    required
                                />
                                {errors.email}
                            </div>
                        </>
                    );
                }}
            </Formik>
        </Dialog>
    )
}

export default withRouter(ForgotPassword);