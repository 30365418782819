import styled from 'styled-components';
// import { COLORS, FONTS } from "Styles/Constants";

// import SnackbarContent from '@material-ui/core/SnackbarContent';
import Alert from '@material-ui/lab/Alert';

export const SnackbarContentWrapper = styled(Alert)`
    &.themed {
        /* background-color: #fff; */
        /* padding-left    : 30px; */
        margin-top: 42px;

        &.MuiAlert-standardInfo {
            color: #ffffff;
            background-color: rgb(2, 136, 209);

            .MuiAlert-icon {
                color: #ffffff;
            }
        }

        &.MuiAlert-standardSuccess {
            color: #ffffff;
            background-color: rgb(56, 142, 60);

            .MuiAlert-icon {
                color: #ffffff;
            }
        }
        
        &.MuiAlert-standardError {
            color: #ffffff;
            background-color: rgb(211, 47, 47);

            .MuiAlert-icon {
                color: #ffffff;
            }
        }

        .notification-message {
            /* color       : #000; */
            font-size: 15px;
            /* max-width   : calc(100% - 75px); */
        }

        .close-icon {
            color: #a4afb7;
        }
    }
`;
