import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Collapse, Toolbar, List, Divider, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { SidebarWrapper } from "./Sidebar.style";
import Routes from "Routes/RoutesList";

function Sidebar(props) {
    let [routes, setRoutes] = useState([]);
    let [openSubmenu, setOpenSubmenu] = useState("");

    useEffect(() => {
        const newRoutes = [...Routes];
        let filtered = newRoutes.filter((route) => route.sidebar);

        if (parseInt(localStorage.getItem("user")) === 3) {
            const findCompanyManagementMenuIndex = filtered.findIndex(value => value.path === "company_management");
            if (findCompanyManagementMenuIndex !== -1) {
                const findCompanyGroupMenuIndex = filtered[findCompanyManagementMenuIndex].sidebar.nested.findIndex(value => { if (value) return value.name === 'Company Group'; return null });
                if (findCompanyGroupMenuIndex !== -1) {
                    delete filtered[findCompanyManagementMenuIndex].sidebar.nested[findCompanyGroupMenuIndex];
                }
            }
        }

        setRoutes(filtered)
    }, [])

    function listItemClick(route) {
        if (route.sidebar.nested) {
            setOpenSubmenu((route.path !== openSubmenu) ? route.path : "")
            return;
        }
        props.history.push(route.path)
    }

    return (
        <SidebarWrapper variant="permanent">
            <Toolbar />
            <div className="list-container">
                <List>
                    {
                        routes.map((route, index) =>
                            <React.Fragment key={index}>
                                {
                                    route.sidebar.showDivider &&
                                    <Divider />
                                }
                                {route.sidebar.visible === undefined || parseInt(localStorage.getItem("user")) === 1 || parseInt(localStorage.getItem("user")) === 3 ?
                                    <ListItem
                                        button
                                        selected={route.path === props.history.location.pathname}
                                        onClick={() => { listItemClick(route) }}>
                                        <ListItemIcon>
                                            {route.sidebar.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={route.sidebar.name} />
                                        {route.sidebar.nested &&
                                            <>
                                                {
                                                    (openSubmenu === route.path) ? <ExpandLessIcon /> : <ExpandMoreIcon />
                                                }
                                            </>
                                        }
                                    </ListItem>
                                    : null}
                                {
                                    route.sidebar.nested &&
                                    <Collapse in={openSubmenu === route.path} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {
                                                route.sidebar.nested.map((child) =>
                                                    child.visible || (parseInt(localStorage.getItem("user")) === 1 || parseInt(localStorage.getItem("user")) === 3) ?
                                                        <>
                                                            {child.name !== 'Label Template' ?
                                                                <>
                                                                    <ListItem
                                                                        button
                                                                        className="nested"
                                                                        key={child.path}
                                                                        selected={child.path === props.history.location.pathname}
                                                                        onClick={() => { props.history.push(child.path) }}
                                                                    >
                                                                        <ListItemIcon>
                                                                            {child.icon}
                                                                        </ListItemIcon>
                                                                        <ListItemText primary={child.name} />
                                                                    </ListItem>
                                                                </>
                                                                :
                                                                <>
                                                                    {parseInt(localStorage.getItem("user")) === 2 ?
                                                                        <ListItem
                                                                            button
                                                                            className="nested"
                                                                            key={child.path}
                                                                            selected={child.path === props.history.location.pathname}
                                                                            onClick={() => { props.history.push(child.path) }}
                                                                        >
                                                                            <ListItemIcon>
                                                                                {child.icon}
                                                                            </ListItemIcon>
                                                                            <ListItemText primary={child.name} />
                                                                        </ListItem> : null}
                                                                </>
                                                            }

                                                            {/* <ListItem
                                                                button
                                                                className="nested"
                                                                key={child.path}
                                                                selected={child.path === props.history.location.pathname}
                                                                onClick={() => { props.history.push(child.path) }}
                                                            >
                                                                <ListItemIcon>
                                                                    {child.icon}
                                                                </ListItemIcon>
                                                                <ListItemText primary={child.name} />
                                                            </ListItem> */}

                                                        </>
                                                        : null
                                                )
                                            }
                                        </List>
                                    </Collapse>
                                }

                            </React.Fragment>
                        )
                    }
                </List>
            </div>
        </SidebarWrapper>
    );
}

export default withRouter(Sidebar);