import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { BREAKPOINTS_VALUE, COLORS, DIMENTIONS } from "./Styles/Constants";
import { lighten } from '@material-ui/core/styles';
import './App.css';

const breakpoints = createBreakpoints({
		keys: ['xs','sm', 'md' , 'lg' , 'xl'],
		values: {
			xs: 0,
			sm: BREAKPOINTS_VALUE.PHABLET,
			md: BREAKPOINTS_VALUE.TABLET,
			lg: BREAKPOINTS_VALUE.LAPTOP,
			xl: BREAKPOINTS_VALUE.DESKTOP
		}
});

export const THEME_SETTINGS = {
	palette: {
		primary: {
			light	: COLORS.PRIMARY_LIGHT,
			main	: COLORS.PRIMARY,
			dark	: COLORS.PRIMARY_DARK,
			contrastText : "#fff"
		},
		secondary: {
			light	: COLORS.SECONDARY_LIGHT,
			main	: COLORS.SECONDARY,
			dark	: COLORS.SECONDARY_DARK,
		},
	},
	breakpoints,
	overrides: {
		MuiAppBar: {
			root: {
				height : `${DIMENTIONS.TOPBAR}px`,
			}
		},
		MuiButton: {
			contained: {
				color : '#000',
			},
			containedPrimary: {
				color : 'white',
			},
			containedSecondary: {
				color : 'white',
			},
			outlinedPrimary: {
				borderWidth : '2px',
				
				'&:hover' : {
					borderWidth : '2px'
				}
			},
			sizeLarge: {
				fontSize: '16px'
			}
		},
		MuiTableRow: {
			root:{
				'&$selected, &$selected:hover': {
					backgroundColor: lighten(COLORS.PRIMARY_LIGHT, 0.85),
				},
			}
		}
	},
	typography: {
		fontFamily		: 'Roboto',
		h6: {
		  "fontWeight": 500,
		},
	  },
	// typography: {
	// 	useNextVariants	: true,
	// 	fontFamily		: "Gotham Book",
	// }
}
