import axios from 'axios';
import CODES from './StatusCodes';
import { store } from 'Redux/Store';
import { logOutUser } from 'Redux/Auth/Actions';
import { showToast } from 'Redux/General/Actions';
import { isIEBrowser } from 'Helpers/Utils';

const METHOD = {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    DELETE: 'delete',
};

const BASEURL = process.env.REACT_APP_API_URL;

// CHECK BELOW FOR SAMPLE DATA TO BE PASSED
class Api {
    isLoggedIn = false;

    constructor() {
        this.baseURL = BASEURL;
        this.getAuthenticationInfo();
    }

    getAuthenticationInfo() {
        if (localStorage.getItem('isLoggedIn')) {
            this.isLoggedIn = true;
            this.accessToken = localStorage.getItem('authToken');
        }

        if (localStorage.getItem('IsCompanyManagerLoggedIn') && localStorage.getItem('IsCompanyManagerLoggedIn') === 'true') {
            this.accessToken = localStorage.getItem('authToken');
        }
    }

    // URL FOR API
    // REFER SAMPLE JSON AT BOTTOM FOR DATA VALUES
    get(url, data, isShowToast = false) {
        return new Promise((resolve, reject) => {
            this.api(METHOD.GET, url, data, isShowToast)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    // console.log(error);
                });
        });
    }

    post(url, data, isShowToast = true) {
        return new Promise((resolve, reject) => {
            this.api(METHOD.POST, url, data, isShowToast)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    // console.log(error);
                    reject(error);
                });
        });
    }

    put(url, data) {
        return new Promise((resolve, reject) => {
            this.api(METHOD.PUT, url, data, true)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    // console.log(error);
                    reject(error);
                });
        });
    }

    delete(url, data) {
        return new Promise((resolve, reject) => {
            this.api(METHOD.DELETE, url, data, true)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    // console.log(error);
                });
        });
    }

    api(method, url, data, isShowToast) {
        return new Promise((resolve, reject) => {
            let axiosConfig = {};
            axiosConfig.method = method;

            axiosConfig.url = this.baseURL + url;

            axiosConfig.headers = this.setHeaders(data);
            if (data) {
                if (data.params) axiosConfig.params = data.params;

                if (data.data) axiosConfig.data = data.data;
            }

            if (isIEBrowser()) {
                if (!axiosConfig.params) axiosConfig.params = {};
                axiosConfig.params.time = new Date().getTime();
            }

            axios(axiosConfig)
                .then((response) => {
                    if (response.data.status === CODES.UNAUTHORIZED) {
                        store.dispatch(logOutUser());
                    } else if (response.data.status === CODES.SERVER_ERROR) {
                        if (data && !data.skipToast) store.dispatch(showToast('Internal Server Error', 'error'));
                        if (data && data.skipErrorHandling) reject(response.data);
                    } else {
                        resolve(response.data);

                        if (isShowToast) {
                            let lastString = url.split('/')[url.split('/').length - 1];
                            // lastString !== "list" &&
                            if (lastString !== 'logout' && lastString !== 'login') store.dispatch(showToast(response.data.message, 'success'));
                        }
                    }
                })
                .catch((error) => {
                    // console.log("ERROR", error);
                    // if(error.status)
                    //     store.dispatch(showToast("Internal Server Error"))
                    // alert(error)
                    /* if(!data.skipErrorHandling){
                        alert(error.message);
                    } else {
                        resolve(error)

                    } */

                    //DEFAULT ERROR HANDLING
                    if (error.response) {
                        if (error.response.status === CODES.UNAUTHORIZED) {
                            store.dispatch(logOutUser());
                            store.dispatch(showToast(error.response.data.message, 'error'));
                        } else if (error.response.status === CODES.VALIDATION_ERROR) store.dispatch(showToast(error.response.data.data[0].msg, 'error'));
                        else if (error.response.status === CODES.BAD_REQUEST) {
                            if (error?.response?.data?.message) {
                                store.dispatch(showToast(error?.response?.data?.message, 'error'));
                            } else {
                                store.dispatch(showToast(error.message, 'error'));
                            }
                        } else if (error.response.data.message) store.dispatch(showToast(error.response.data.message, 'error'));
                    } else {
                        store.dispatch(showToast('Internal Server Error', 'error'));
                    }
                    reject(error);
                });
        });
    }

    setHeaders(data) {
        let headers = {};
        headers['accept-language'] = 'en';
        headers['Content-Type'] = 'application/json';
        // headers['ngrok-skip-browser-warning'] = true;

        if (data) {
            if (data.isMultipart) {
                headers['Content-Type'] = 'multipart/form-data';
            }

            if (data.headers) {
                for (var key in data.headers) {
                    if (data.headers.hasOwnProperty(key)) {
                        headers[key] = data.headers[key];
                    }
                }
            }
        }

        if (this.isLoggedIn !== false && !(data && data.skipAuth)) {
            // headers['Authorization'] = this.accessToken;
            headers['accessToken'] = this.accessToken;
        }

        if (localStorage.getItem('IsCompanyManagerLoggedIn') && localStorage.getItem('IsCompanyManagerLoggedIn') === 'true') {
            headers['accessToken'] = this.accessToken;

            if (localStorage.getItem('IsCompanyManagerLoggedIn') && localStorage.getItem('groupCompanyId') && localStorage.getItem('groupCompanyId') !== 'null') {
                headers['groupCompanyId'] = localStorage.getItem('groupCompanyId');
            }
        }
        return headers;
    }
}

// SAMPLE DATA JSON
/* let sample_data = {

    // ADDITIONAL HEADERS IF REQUIRED
    headers :{
        'Content-type'  : 'xx-urlencode',
    },

    // IF USER ID AND TOKEN SHOULDN'T BE PASSED IN HEADERS (USER FOR AFTER LOGIN API)
    // DEFAULT : FALSE;
    skipAuth    : false,

    // IF Default error handling needs to be overridden
    // DEFAULT : FALSE;
    skipErrorHandling    : false,

    // FOR SENDING FILES OR FORM DATA REQUEST
    isMultipart : true,

    // `PARAMS` ARE THE URL PARAMETERS TO BE SENT WITH THE REQUEST
    params : {
        user_id     : 10,
        name        : "lorem",
        page        : 3,
        sort_by     : 'name'
    },

    // POST DATA
    data : {
        firstName   : 'Lorem',
        lastName    : 'Ipsum'
    },
} */

export default Api;
