import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';


export default function CustomizedDialog(props) {

    const { isOpen, handleClose, children, title, maxWidth, handleSave, submitText } = props;

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={isOpen}
            maxWidth={maxWidth}
        >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                {title}
            </DialogTitle>
            {children &&
                <DialogContent dividers>
                    {children}
                </DialogContent>
            }
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    No
                </Button>
                <Button autoFocus onClick={handleSave} color="primary">
                    {submitText || "Save Changes"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}